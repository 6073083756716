import React, { useEffect, useRef } from 'react'

type Props = {
  readonly children: React.ReactNode
  readonly isOpen: boolean
  readonly setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const Dropdown: React.FC<Props> = ({ children, isOpen }) => {
  const contentRef = useRef(null)

  useEffect(() => {
    handleTransitionEnd()
  }, [])

  // avoid animating scrollbar for each content
  const handleTransitionEnd = () => {
    if (isOpen) {
      contentRef.current.style.overflow = 'auto'
    } else {
      contentRef.current.style.overflow = 'hidden'
    }

    if (!isOpen) {
      contentRef.current.style.overflow = 'hidden'
    } else {
      contentRef.current.style.overflow = 'auto'
    }
  }

  return (
    <div
      ref={contentRef}
      className={`transition-max-h  ${
        isOpen
          ? 'max-h-[100vh] overflow-auto duration-700 ease-in'
          : 'ease-[cubic-bezier(0,-0.04,0,1.02)] max-h-0 overflow-hidden duration-300'
      }`}
      style={{ overflow: isOpen ? 'hidden' : 'auto' }}
      onTransitionEnd={handleTransitionEnd}
    >
      {children}
    </div>
  )
}

export default Dropdown
