'use client'

import { FormProvider, useForm } from 'react-hook-form'
import Icon from '@/src/components/icons/Icon'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import React from 'react'
import { getTranslate } from '@/src/utilities/i18n'
import SelectController from '@/src/components/formFields/SelectController'
import capitalize from 'lodash/capitalize'
import InputField from '@/src/components/formFields/InputField'
import CheckboxField from '@/src/components/formFields/CheckboxField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const VoucherForm = ({ services }) => {
  const { t } = getTranslate()

  const serviceOptions = services.map((service) => {
    return {
      label: capitalize(service.name),
      value: `${service.id}`,
    }
  })

  const numberOptions = [...Array(10)].map((_, key) => {
    const val = key + 1
    return {
      label: `${val}`,
      value: `${val}`,
    }
  })

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      service: serviceOptions.find((item) => item.value === '1'),
      numberOfAppointments: { label: '1', value: '1' },
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('validationErrors.email.badEmailFormat').required('validationErrors.required'),
      }),
    ),
  })

  const submitForm = async (data: Record<string, any>) => {
    console.log(data)
  }

  const handleSubmit = form.handleSubmit(submitForm, (e) => {
    console.log(e)
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        {form.formState.isSubmitSuccessful ? (
          <div className="flex items-center rounded-lg bg-blue-500 px-2 py-4 text-base font-semibold">
            <Icon icon="check-inline" color="secondary" className="mr-2" />
            {t('components.pageBlocks.form.formSent')}
          </div>
        ) : (
          <>
            <div className="mb-5 w-full">
              <SelectController
                name={'service'}
                label={t('components.giftVoucher.serviceSelect')}
                options={serviceOptions}
              />
              <SelectController
                name={'numberOfAppointments'}
                label={t('components.giftVoucher.numberOfAppointments')}
                options={numberOptions}
              />

              <InputField
                name={'email'}
                label={
                  <>
                    {t('components.giftVoucher.email')}&nbsp;
                    <span className="text-sm font-normal normal-case">{t('components.giftVoucher.note')}</span>
                  </>
                }
                required
              />

              <CheckboxField label={t(`components.giftVoucher.consent`)} name="consent" className="my-8 flex" />
            </div>

            <div className="grid w-full grid-cols-1 gap-4 rounded-xl bg-sand-500 px-5 py-4 text-secondary tablet:grid-cols-2">
              <div className="flex items-center justify-between text-3xl font-semibold tablet:flex-col tablet:items-start">
                <div className="text-sm font-bold">{t('components.giftVoucher.totalCost')}</div>
                <div className="font-serif">1290Kč</div>
              </div>

              <RadixButton
                loading={form.formState.isSubmitting}
                onClick={handleSubmit}
                className="w-full tablet:w-auto"
                size="medium"
              >
                {t('component.button.payWithCard')}
              </RadixButton>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  )
}

export default VoucherForm
