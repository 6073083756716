import { RadixButtonLink } from '@/src/rebrand/buttonRadix'
import React from 'react'
import { cn } from '@/src/rebrand/utilities'
import Markdown from 'markdown-to-jsx'

const Heading: React.FC<{
  text: string
  inverted?: boolean
}> = ({ text, inverted = false }) => (
  <h2 className={cn('mb-3 text-balance text-3xl leading-9', inverted ? 'text-white' : 'text-secondary')}>{text}</h2>
)
const Text = ({ children, className = '' }) => (
  <div className={cn('mb-6 text-lg', className)}>
    {typeof children === 'string' ? <Markdown>{children}</Markdown> : children}
  </div>
)

type Props = {
  headlineText: string
  text?: React.ReactNode
  image?: React.ReactNode
  buttonText?: string
  buttonLink?: string
  badgeText?: string
  align?: 'left' | 'center' | 'right'
  inverted?: boolean
  className?: string
}

const ContentHeader: React.FC<Props> = ({
  className = '',
  headlineText,
  text,
  buttonLink,
  buttonText,
  image,
  badgeText,
  align = 'center',
  inverted = false,
}) => {
  return (
    <div
      className={cn(
        'flex flex-col',
        align === 'left' ? 'items-start' : align === 'right' ? 'items-end' : 'items-center',
        className,
      )}
    >
      {image ?? null}
      {badgeText && (
        <div className="mb-4 rounded-lg bg-secondary px-2 py-1 text-base font-semibold leading-[22px] text-pink-500">
          {badgeText}
        </div>
      )}
      <div className="">
        <Heading inverted={inverted} text={headlineText} />
        {text && <Text className="mb-0">{text}</Text>}
      </div>
      {buttonText && buttonLink && (
        <RadixButtonLink href={buttonLink} size="default">
          {buttonText}
        </RadixButtonLink>
      )}
    </div>
  )
}

export { ContentHeader, Text, Heading }
