import Icon from '@/src/components/icons/Icon'
import React from 'react'
import { getTranslate } from '@/src/utilities/i18n'
import { IconSizeType } from '@/src/types/ComponentTypes'
import { STYLE_LIMITS } from '@/src/components/recommendedTherapist/constants'
import { isNewBrandingActive } from '@/src/rebrand/utilities'

type Props = {
  readonly value: number
  readonly label: string
  readonly iconSize?: IconSizeType
}

const StyleMatch: React.FC<Props> = ({ value, label, iconSize }) => {
  const { t } = getTranslate()
  const size = iconSize ?? 'md'
  const isPoor = value < STYLE_LIMITS.ok

  return (
    <>
      <div className={`relative flex w-full ${value > 0 ? (isNewBrandingActive ? 'mt-[65px]' : 'mt-[75px]') : ''}`}>
        <div className={`h-3 w-1/4 rounded-md ${isNewBrandingActive ? 'bg-pink-500' : 'bg-bordeaux-light'}`} />
        <div className={`h-3 w-1/4 rounded-md ${isNewBrandingActive ? 'bg-blue-500' : 'bg-bordeaux-lighter'}`} />
        <div className={`h-3 w-1/4 rounded-md ${isNewBrandingActive ? 'bg-secondary' : 'bg-petroleum-light'}`} />
        <div className={`h-3 w-1/4 rounded-md ${isNewBrandingActive ? 'bg-secondary' : 'bg-petroleum'}`} />

        {value > 0 && (
          <div className="absolute -top-1/2 -ml-3" style={{ left: `${value}%` }}>
            <div
              className={`flex h-6 w-6 items-center justify-center rounded-full ${
                isNewBrandingActive ? 'bg-primary' : 'bg-white shadow-hedepy'
              }`}
            >
              <div className={`h-[14px] w-[14px] rounded-full ${isNewBrandingActive ? 'bg-white' : 'bg-primary'}`} />
            </div>

            <div
              className={`absolute ${
                isPoor ? 'left-1/2' : '-right-[90px]'
              } -top-[45px] flex max-w-[175px] -translate-x-1/2 transform whitespace-nowrap rounded-md ${
                isNewBrandingActive ? '-top-[35px] bg-yellow-500 text-xs' : '-top-[45px] bg-white shadow-hedepy'
              } px-2 py-1 text-base font-bold text-petroleum`}
            >
              <div
                className={`absolute -bottom-[3px] ${
                  isPoor ? 'left-1/2' : 'right-0'
                } -bottom-[3px] h-4 w-4 -translate-x-1/2 rotate-45 transform ${
                  isNewBrandingActive ? 'bg-yellow-500' : 'bg-white'
                }`}
              ></div>
              <span className="relative z-10 w-[175px] whitespace-nowrap text-center">
                {t(`component.recommendedTherapist.style.${label}.label`)}
              </span>
            </div>
          </div>
        )}
      </div>
      {isNewBrandingActive ? null : (
        <div className="mt-4 flex justify-between">
          <Icon icon="dislike" color="primary" size={size} />
          <Icon icon="like" color="primary" size={size} />
        </div>
      )}
    </>
  )
}

export default StyleMatch
