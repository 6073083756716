import useSWRImmutable from 'swr/immutable'
import { useApis } from '../../../contexts/api-context'
import { ExtendedDiagnosisResponse } from '../../diagnosis/guide/types'

const fetchDiagnosis = async (api): Promise<ExtendedDiagnosisResponse> => {
  const res = await api.getDiagnosis()
  return res.data
}

const useGetDiagnosis = () => {
  const { api } = useApis()
  const { data, error, mutate } = useSWRImmutable(['useGetDiagnosis'], () => fetchDiagnosis(api))

  return { data, isLoading: !error && !data, refetchDiagnosis: mutate }
}

export default useGetDiagnosis
