import React from 'react'
import Markdown from 'markdown-to-jsx'
import Icon from './Icon'
import { bm, be } from '../../utilities/bliss'
import type { IconPropsType } from './Icon'
import type { ThemeColorsType } from '../../types/ComponentTypes'
import { isNewBrandingActive } from '@/src/rebrand/utilities'

type IconTextPropTypes = IconPropsType & {
  text: string
  reversed?: boolean
  center?: boolean
  iconColor?: ThemeColorsType
  textModifier?: string
  headline?: string
  iconClass?: string
}

const IconText: React.FC<IconTextPropTypes> = ({
  className,
  text,
  icon,
  iconColor,
  color,
  size,
  svgType,
  reversed,
  center,
  textModifier,
  headline,
  iconClass,
}) => {
  const classes: string[] = []

  if (className) {
    classes.push(className)
  }
  if (color) {
    classes.push(`text-color-${color}`)
  }

  const renderIcon = () => (
    <Icon
      className={be('icon-text', 'icon', [], iconClass)}
      color={iconColor}
      icon={icon}
      size={size}
      svgType={svgType}
    />
  )

  const renderText = () => (
    <div>
      {headline && <h3 className="text-text-color">{headline}</h3>}
      <Markdown className={be('icon-text', 'text', textModifier, isNewBrandingActive ? 'font-normal' : '')}>
        {text}
      </Markdown>
    </div>
  )

  return (
    <div
      className={bm(
        'icon-text',
        [reversed ? 'reversed' : '', center ? 'center' : ''],
        classes ? classes.join(' ') : '',
      )}
    >
      {reversed ? (
        <>
          {renderIcon()}
          {renderText()}
        </>
      ) : (
        <>
          {renderText()}
          {renderIcon()}
        </>
      )}
    </div>
  )
}

IconText.defaultProps = {
  className: '',
  icon: 'heart',
  color: 'secondary',
  iconColor: null,
  size: 'native',
  svgType: 'symbolic',
  reversed: true,
  center: false,
}

export default IconText
