import React from 'react'

import StyleMatch from '@/src/components/recommendedTherapist/StyleMatch'
import useGetMatchingTherapist from '@/src/components/diagnosis/guide/hooks/useGetMatchingTherapist'
import { styleMatchText } from '@/src/components/diagnosis/guide/utils'
import { IconSizeType } from '@/src/types/ComponentTypes'
import { STYLE_LIMITS } from '@/src/components/recommendedTherapist/constants'
import { cn, isNewBrandingActive } from '@/src/rebrand/utilities'

type Props = {
  readonly therapistId: number
  readonly iconSize?: IconSizeType
  readonly title?: React.ReactNode
  readonly className?: string
  readonly listing?: boolean
}

const TherapeuticStyleProfile: React.FC<Props> = ({
  therapistId,
  iconSize,
  title,
  className = '',
  listing = false,
}) => {
  const { matchingTherapistData } = useGetMatchingTherapist(therapistId)

  const label = styleMatchText(matchingTherapistData?.styleMatch)

  const headline = title && <strong>{title}</strong>

  if (!matchingTherapistData?.styleMatch) {
    return null
  }

  if (listing) {
    return (
      <>
        {isNewBrandingActive && <hr className="-mx-5 -mb-1 mt-6" />}
        <div className={cn(isNewBrandingActive ? '' : 'rounded-xl bg-white p-5', className)}>
          {headline}
          <StyleMatch value={matchingTherapistData?.styleMatch || 0} label={label} iconSize={iconSize} />
        </div>
      </>
    )
  }

  return matchingTherapistData && matchingTherapistData.styleMatch > STYLE_LIMITS.ok ? (
    <div className={`${className} rounded-xl bg-white p-5`}>
      {headline}
      <StyleMatch value={matchingTherapistData.styleMatch} label={label} iconSize={iconSize} />
    </div>
  ) : null
}

export default TherapeuticStyleProfile
