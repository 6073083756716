import { Router, schema } from 'next-roots'

const router = new Router(schema)

const WP_URL_WITHOUT_TRAILING_SLASH = (process.env.NEXT_PUBLIC_WORDPRESS_WEB || '').replace(/\/$/, '')

const replacements = {
  '/articles/about-therapy': router.getHref('/about-therapy'),
  '/articles/topics': router.getHref('/topics'),
  '/articles/blog': router.getHref('/about-mental-wellbeing'),
  '/articles/tests': router.getHref('/tests'),
}

const domainRegex = /https:\/\/(?:app\.)?hedepy\.[^/]+/

export const rewriteKnownUrlToPublic = (url: string) => {
  if (!url) return url

  let newLink = url
  for (const [oldPath, newPath] of Object.entries(replacements)) {
    const regex = new RegExp(`${domainRegex.source}${oldPath}`)
    newLink = newLink.replace(regex, `${WP_URL_WITHOUT_TRAILING_SLASH}${newPath}`)
  }
  return newLink
}

export const rewriteMarkdownLinksToPublic = (input: string) => {
  const markdownLinkRegex = new RegExp(`(?<!\\!)\\[([^\\]]+)\\]\\((${domainRegex.source}/articles[^)]*)\\)`, 'g')
  return input.replace(markdownLinkRegex, (_match, text, link) => {
    const newLink = rewriteKnownUrlToPublic(link)
    return `[${text}](${newLink})`
  })
}

export const replaceLinksInStrapiDataToPublic = (data: Record<string, any>) => {
  const stringifiedData = JSON.stringify(data)

  const modifiedStringifiedData = rewriteMarkdownLinksToPublic(stringifiedData)
  return JSON.parse(modifiedStringifiedData)
}
