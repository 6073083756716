import { Translate } from 'next-translate'

export const getFullName = ({
  person,
  titleAfter,
  titleBefore,
  t,
}: {
  person: { firstName: string; lastName: string }
  t: Translate
  titleBefore?: string
  titleAfter?: string
}) => {
  if (!person) return t('general.unknownUser')

  return person.lastName
    ? `${titleBefore ? `${titleBefore} ` : ''}${person.firstName} ${person.lastName}${
        titleAfter ? ` ${titleAfter}` : ''
      }`
    : person.firstName
}
