'use client'

import { getTranslate } from '@/src/utilities/i18n'
import React, { useState } from 'react'
import { ContentHeader } from './ContentHeader'
import Container from '@/src/rebrand/Container'
import EllipsisIcon from '../header/EllipsisIcon'
import Icon from '../icons/Icon'
import { RadixButton, RadixButtonLink } from '@/src/rebrand/buttonRadix'
import Link from 'next/link'
import { getColor } from './utils'

type TagList = {
  iconName: string
  iconBgColor: string
  title: string
  tags: {
    name: string
    articleLink: string
  }[]
}

const IconWithTitle: React.FC<TagList> = ({ iconBgColor, iconName, title, tags }) => {
  const [tagsVisible, setTagsVisible] = useState(false)
  return (
    <div className="mb-4 flex flex-col justify-between text-left tablet:items-center tablet:text-center">
      <div className="flex items-center tablet:flex-col">
        <EllipsisIcon color={iconBgColor} className="shrink-0">
          <Icon icon={iconName ?? 'relationships-rebrand'} size="md" width={30} height={30} />
        </EllipsisIcon>
        <h4 className="m-3 hidden text-xl tablet:block tablet:text-center">{title}</h4>
        <RadixButton
          variant="inline"
          onClick={() => setTagsVisible(!tagsVisible)}
          className="flex grow justify-between !no-underline tablet:hidden"
        >
          <h4 className="m-3 ml-5 flex text-wrap text-left text-xl normal-case text-petrol-500">{title}</h4>

          <Icon icon="select-arrow" size="xs" className={`text-peach-500  ${tagsVisible ? 'rotate-180' : ''}`} />
        </RadixButton>
      </div>

      <div
        className={`flex flex-wrap justify-start tablet:justify-center ${
          tagsVisible ? 'flex' : 'hidden tablet:flex'
        } ml-20 tablet:ml-0 `}
      >
        {tags.map((tag, index) => {
          return (
            <Link
              href={tag.articleLink}
              className="m-1 rounded-xl bg-sand-500 px-3 py-2 text-sm font-bold uppercase text-petrol-500"
              key={index}
            >
              {tag.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

type TopicsBlockProps = {
  data: {
    tagList: TagList[]
  }
}

const TopicsBlock: React.FC<TopicsBlockProps> = ({ data }) => {
  const { t } = getTranslate()

  return (
    <div className="bg-white text-center">
      <Container className="py-14 tablet:py-[120px]">
        <div className="mx-auto mb-2 max-w-[660px] tablet:mb-16">
          <ContentHeader
            headlineText={t('components.pageBlocks.topics.title')}
            text={t('components.pageBlocks.topics.note')}
            align="center"
          />
        </div>
        <div className="tablet:grid tablet:grid-cols-4 tablet:gap-4">
          {(data.tagList || []).map((tag, index) => {
            return (
              <IconWithTitle
                key={`tagList-${index}`}
                iconBgColor={getColor(tag.iconBgColor)}
                iconName={tag.iconName}
                title={tag.title}
                tags={tag.tags}
              />
            )
          })}
        </div>
        <RadixButtonLink href="/diagnosis" size="default" className="mt-8 w-full tablet:mt-14 tablet:w-auto">
          {t('component.button.findYourTherapist')}
        </RadixButtonLink>
      </Container>
    </div>
  )
}

export default TopicsBlock
