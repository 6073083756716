import { NextRequest, NextResponse } from 'next/server'

// Ensure a UUID cookie is always set to enable experimentation
export const GB_UUID_COOKIE = 'GROWTHBOOK_DEVICE_ID'

export function middleware(request: NextRequest) {
  // Generate a UUID if it doesn't exist yet
  let uuid = request.cookies.get(GB_UUID_COOKIE)?.value
  let needsUpdate = false
  if (!uuid) {
    uuid = crypto.randomUUID()
    needsUpdate = true

    // Set the cookie on the current request
    request.cookies.set(GB_UUID_COOKIE, uuid)
  }

  const requestHeaders = new Headers(request.headers)
  requestHeaders.set('x-forwarded-for', request.ip)

  // Forward the request to the server
  const response = NextResponse.next({
    request: {
      headers: new Headers(request.headers),
    },
  })

  // Add the newly created UUID to the response headers to persist in the browser
  if (needsUpdate) {
    response.cookies.set(GB_UUID_COOKIE, uuid)
  }

  return response
}
